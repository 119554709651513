<template>
  <VueContentHolder id="divContractsWrapper" :padding="[25, 25, 20]">
    <VueHeadline level="2" weightLevel="3">{{ items[0].header }}</VueHeadline>
    <VueSpacer level="20"></VueSpacer>

    <VueText class="contract-body" weightLevel="2" sizeLevel="4" color="grey-40">
      {{ items[0].text }}</VueText
    >
  </VueContentHolder>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'Contracts',
  components: { VueText, VueHeadline, VueSpacer, VueContentHolder },
  data() {
    return {
      items: [
        {
          header: 'Sözleşme',
          text: '',
        },
      ],
    };
  },
  created() {
    this.setBottomBarStatus(false);
    StaticContent.getStaticContent('Sozlesme').then(res => {
      if (res.data.Data) {
        for (let item of this.items) {
          item.text = res.data.Data.content;
        }
      }
    });
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
  },
};
</script>

<style scoped lang="scss">
.contract-body {
  line-height: 1.86;
}
</style>
